<template>
  <div @dblclick="handleClick">
    <div class="row items-center">
      <q-badge
        v-if="options.title"
        :label="options.title"
        :color="data ? options.selectedColor : options.defaultColor"
      />

      <div
        v-if="options.description"
        class="text-caption text-capitalize q-ml-sm"
      >
        {{ $t(options.description) }}
      </div>
    </div>

    <div v-if="data" class="row text-center text-subtitle1 no-wrap q-px-md q-py-sm" style="overflow-x: auto;white-space: nowrap;">
      <template
        v-for="(item, i) in path"
        :key="`${item}: ${i}`"
      >
<!--        <div-->
<!--          class="col q-px-lg q-py-sm"-->
<!--          style="white-space: pre-wrap !important;"-->
<!--        >-->
          <strong>{{ item || 0 }}</strong>
<!--        </div>-->

        <div
          v-if="i + 1 < path.length"
          class="col"
        >
          -
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PlaceSectionObject',
  props: {
    rawData: {
      type: Object,
      default () {
        return {}
      }
    },
    data: {
      type: [Object, null],
      default () {
        return null
      }
    }
  },
  data () {
    return {
      appOptions: this.$appOptions,
      path: [],
      sub: null
    }
  },
  computed: {
    options () {
      const defaultOptions = {
        title: '',
        description: this.data.type,
        defaultColor: 'amber',
        selectedColor: 'positive'
      }

      if (this.rawData.options) {
        if (this.rawData.options.title) {
          defaultOptions.title = this.rawData.options.title
        }

        if (this.rawData.options.description) {
          defaultOptions.description = this.rawData.options.description
        }
      }

      return defaultOptions
    }
  },
  watch: {
    data (value) {
      if (value) {
        this.loadPath(value)
      }
    }
  },
  mounted () {
    if (this.data) {
      this.loadPath(this.data)
    }

    this.sub = this.$eventBus.subscribe('rules', ({ appOptions }) => {
      this.appOptions = appOptions
    })
  },
  unmounted () {
    this.sub.unsubscribe()
  },
  methods: {
    loadPath (place) {
      if (!place.path) {
        place.path = [place.type].map(x => x || 0)
      }

      this.path = [...place.path, (place.placeAlias || '')]
    },
    handleClick () {
      this.$emit('click', this.rawData)
    }
  }
}
</script>
